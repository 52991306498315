import {
  getGroupRoleListApi,
  updateGroupRoleListApi,
} from '@/api/action-defines/user-management';
import { mapState, mapGetters } from 'vuex';
import { showToast, removeAccents } from '@/@core/utils/utils';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';

export default {
  name: 'RoleList',
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      roleDetail: [],
      lv1Active: '',
      activeRole: '',
      activeCollapse: null,
      keyword: '',
      isLoading: false,
      isLoadingLv1: false,
    };
  },
  computed: {
    ...mapState({
      currentSiteID: (state) => state.auth.user.SiteID,
      $ls_GroupUserRoles: (state) => state.app.resources.GroupUsers,
    }),
    ...mapGetters({
      $lsGet_userSiteGroupRoleInfo: 'app/userSiteGroupRoleInfo'
    }),
    currentUserSiteGroupRoleInfo() {
      return this.$lsGet_userSiteGroupRoleInfo(this.currentSiteID);
    },
    groupRolesAllowConfig() {
      if (this.currentUserSiteGroupRoleInfo) {
        return this.$ls_GroupUserRoles
          .filter(s => s.GroupRoleLevel > this.currentUserSiteGroupRoleInfo.GroupRoleLevel)
          .sort((a, b) => a.GroupRoleLevel - b.GroupRoleLevel);
      }
      return [];
    },
  },
  created() {},
  methods: {
    async viewDetail(groupId) {
      this.isLoadingLv1 = true;
      this.activeRole = groupId;
      this.lv1Active = '';

      await getGroupRoleListApi({
        QueryParameters: { groupUserId: groupId },
      }).then((res) => {
        this.roleDetail = res.Data;
      });
      this.isLoadingLv1 = false;
    },
    changeStatusLv1(indexLv1, value) {
      this.roleDetail[indexLv1].Indeterminate = false;
      this.roleDetail[indexLv1].Childs.forEach((x) => {
        x.Status = value;
        x.Indeterminate = false;
        x.Childs.forEach((y) => {
          y.Status = value;
          y.Indeterminate = false;
        });
      });
    },
    changeStatusLv2(indexLv2, value) {
      this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs[
        indexLv2
      ].Indeterminate = false;
      this.roleDetail
        .find((x) => x.MenuCode == this.lv1Active)
        .Childs[indexLv2].Childs.forEach((y) => {
          y.Status = value;
        });

      this.setImmediateLv1();
    },
    changeStatusLv3(indexLv2, indexLv3) {
      if (
        this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs[
          indexLv2
        ].Childs.length ==
        this.roleDetail
          .find((x) => x.MenuCode == this.lv1Active)
          .Childs[indexLv2].Childs.filter((y) => y.Status == true).length
      ) {
        // Set status lv2 true
        this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs[
          indexLv2
        ].Status = true;
        this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs[
          indexLv2
        ].Indeterminate = false;
      } else if (
        this.roleDetail
          .find((x) => x.MenuCode == this.lv1Active)
          .Childs[indexLv2].Childs.filter((y) => y.Status == true).length == 0
      ) {
        this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs[
          indexLv2
        ].Status = false;
        this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs[
          indexLv2
        ].Indeterminate = false;
      } else {
        this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs[
          indexLv2
        ].Status = false;

        this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs[
          indexLv2
        ].Indeterminate = true;
      }

      // Check lv1 đã check đủ hay chưa ?
      // if (
      //   this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs
      //     .length ==
      //   this.roleDetail
      //     .find((x) => x.MenuCode == this.lv1Active)
      //     .Childs.filter((y) => y.Status == true).length
      // ) {
      //   this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Status = true;
      //   this.roleDetail.find(
      //     (x) => x.MenuCode == this.lv1Active
      //   ).Indeterminate = false;
      // } else if (
      //   this.roleDetail
      //     .find((x) => x.MenuCode == this.lv1Active)
      //     .Childs.filter((y) => y.Status == false).length ==
      //   this.roleDetail
      //     .find((x) => x.MenuCode == this.lv1Active)
      //     .Childs.filter((y) => y.Indeterminate == false).length
      // ) {
      //   this.roleDetail.find(
      //     (x) => x.MenuCode == this.lv1Active
      //   ).Status = false;
      //   this.roleDetail.find(
      //     (x) => x.MenuCode == this.lv1Active
      //   ).Indeterminate = false;
      // } else {
      //   this.roleDetail.find(
      //     (x) => x.MenuCode == this.lv1Active
      //   ).Status = false;
      //   this.roleDetail.find(
      //     (x) => x.MenuCode == this.lv1Active
      //   ).Indeterminate = true;
      // }
      // this.$forceUpdate();
      this.setImmediateLv1();
    },
    setImmediateLv1() {
      this.isLoadingLv1 = true;
      if (
        this.roleDetail
          .find((x) => x.MenuCode == this.lv1Active)
          .Childs.filter((y) => y.Indeterminate == true).length &&
        this.roleDetail
          .find((x) => x.MenuCode == this.lv1Active)
          .Childs.filter((y) => y.Indeterminate == true).length ==
          this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs
            .length
      ) {
        // Nếu số lượng Indeterminate = số lượng child.
        this.roleDetail.find(
          (x) => x.MenuCode == this.lv1Active
        ).Status = false;
        this.roleDetail.find(
          (x) => x.MenuCode == this.lv1Active
        ).Indeterminate = true;
      } else if (
        this.roleDetail
          .find((x) => x.MenuCode == this.lv1Active)
          .Childs.filter((y) => y.Indeterminate == true).length == 0 &&
        this.roleDetail
          .find((x) => x.MenuCode == this.lv1Active)
          .Childs.filter((y) => y.Status == true).length == 0
      ) {
        // Nếu số lượng Indeterminate = 0, và status cũng bằng 0
        this.roleDetail.find(
          (x) => x.MenuCode == this.lv1Active
        ).Status = false;
        this.roleDetail.find(
          (x) => x.MenuCode == this.lv1Active
        ).Indeterminate = false;
      } else if (
        this.roleDetail
          .find((x) => x.MenuCode == this.lv1Active)
          .Childs.filter((y) => y.Indeterminate == true).length == 0 &&
        this.roleDetail
          .find((x) => x.MenuCode == this.lv1Active)
          .Childs.filter((y) => y.Status == true).length ==
          this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs
            .length
      ) {
        // Nếu số lượng Indeterminate = 0 và status = child
        this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Status = true;
        this.roleDetail.find(
          (x) => x.MenuCode == this.lv1Active
        ).Indeterminate = false;
      } else if (
        this.roleDetail
          .find((x) => x.MenuCode == this.lv1Active)
          .Childs.filter((y) => y.Indeterminate == true).length == 0 &&
        this.roleDetail
          .find((x) => x.MenuCode == this.lv1Active)
          .Childs.filter((y) => y.Status == true).length <
          this.roleDetail.find((x) => x.MenuCode == this.lv1Active).Childs
            .length
      ) {
        // Nếu số lượng Indeterminate = 0 và 0 < status < child
        this.roleDetail.find(
          (x) => x.MenuCode == this.lv1Active
        ).Status = false;
        this.roleDetail.find(
          (x) => x.MenuCode == this.lv1Active
        ).Indeterminate = true;
      } else {
        this.roleDetail.find(
          (x) => x.MenuCode == this.lv1Active
        ).Status = false;
        this.roleDetail.find(
          (x) => x.MenuCode == this.lv1Active
        ).Indeterminate = true;
      }

      this.isLoadingLv1 = false;
    },
    async updateGroupRole() {
      this.isLoading = true;
      let BodyData = {
        GroupUserID: this.activeRole,
        RolesData: this.roleDetail,
      };

      await updateGroupRoleListApi({ BodyData: BodyData }).then((res) => {
        if (res.IsSuccess) {
          showToast('success', this.$t('common_success'));
        }
      });
      this.isLoading = false;
    },
    searchRole() {
      this.roleDetail
        .find((x) => x.MenuCode == this.lv1Active)
        .Childs.forEach((x) => {
          if (x.Type == 'PAGE') {
            x.Childs.forEach((y) => {
              if (
                removeAccents(this.$t(y.Keyword))
                  .split(' ')
                  .join('')
                  .toUpperCase()
                  .includes(
                    removeAccents(this.keyword)
                      .split(' ')
                      .join('')
                      .toUpperCase()
                  )
              ) {
                y.isHide = false;
              } else {
                y.isHide = true;
              }
            });
          } else {
            if (
              removeAccents(this.$t(x.Keyword))
                .split(' ')
                .join('')
                .toUpperCase()
                .includes(
                  removeAccents(this.keyword).split(' ').join('').toUpperCase()
                )
            ) {
              x.isHide = false;
            } else {
              x.isHide = true;
            }
          }
        });

      this.$forceUpdate();
    },
  },
};
